<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getAddressDetails, patchAddressDetails, postAddressDetails } from "@/services/api/address.api";
import {
  getAddressableObjectTypes,
  getAddressableObjectSubTypesHor,
  getAddressableObjectSubTypesVer,
  getAddressableObjectRoofTypes,
  getAddressTypes,
  getAddressSourceTypes,
} from "@/services/api/masterdata.api";
import { getAddressesOverviewChilds } from "@/services/api/location.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import _ from "lodash";
import useDetailEdit from "@/composables/detailEdit.js";
import DateInput from "@/components/general/DateInput.vue";
import { navigateToLocation } from "@/composables/general.js";
const {
  setMode,
  setReadonlyMode,
  setEditMode,
  setEditTLMode,
  setDetails,
  hasDetails,
  selectNewIndex,
  selectedData,
  updateSelectedDetail,
  resetSelectedDetail,
  isReadOnly,
  isAddingMode,
  isAddingMasterMode,
  isAddingDetailMode,
  isEditDataMode,
  isEditTLMode,
  allowEdit,
  allowEditTL,
  addNewDetailMode,
  detailTabs,
  modDateFormatted,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const props = defineProps({
  id: String,
  locationReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const addressTypes = ref([]);
const addressableObjectTypes = ref([]);
const addressableObjectSubTypesHor = ref([]);
const addressableObjectSubTypesVer = ref([]);
const addressableObjectRoofTypes = ref([]);
const addressSourceTypes = ref([]);
const addressTypesLoaded = ref(false);
const addressableObjectTypesLoaded = ref(false);
const addressableObjectSubTypesHorLoaded = ref(false);
const addressableObjectSubTypesVerLoaded = ref(false);
const addressableObjectRoofTypesLoaded = ref(false);
const addressSourceTypesLoaded = ref(false);
const isSaving = ref(false);

const selectedTabIndex = ref(undefined);

const subHeaders = ref([
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Adr. Type", value: "adrTypDescr" },
  { text: "Adres", value: "compAddr" },
  { text: "Postcode", value: "pc" },
  { text: "Woonplaats", value: "city" },
]);
const subLocations = ref([]);
const subLocationSelected = ref([]);
const dataMustBeRetrieved = ref(false);

const masterDataLoaded = computed(
  () =>
    addressTypesLoaded.value &&
    addressableObjectTypesLoaded.value &&
    addressableObjectSubTypesHorLoaded.value &&
    addressableObjectSubTypesVerLoaded.value &&
    addressableObjectRoofTypesLoaded.value &&
    addressSourceTypesLoaded.value
);
const sourceIsBag = computed(() => selectedData.value.srcTyp === "BAG");
const coordinates = computed(() => {
  if (selectedData.value.lat && selectedData.value.lon) {
    return { lon: selectedData.value.lon, lat: selectedData.value.lat };
  }
  return undefined;
});

watch(
  () => [props.id, props.addModus],
  () => {
    setMode(props.id, props.addModus);
    if (!isAddingMasterMode.value) {
      dataMustBeRetrieved.value = true;
      fetchData();
    }
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (isAddingMasterMode.value) {
      loadSubLocations();
    }
    if (!isAddingMode.value) {
      fetchData();
    }
  },
  { immediate: true }
);

watch(
  () => selectedTabIndex.value,
  () => {
    selectNewIndex(selectedTabIndex.value);
  }
);

onMounted(() => {
  loadAddressTypes();
  loadAddressableObjectTypes();
  loadAddressableObjectSubTypesHor();
  loadAddressableObjectSubTypesVer();
  loadAddressableObjectRoofTypes();
  loadAddressSourceTypes();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("AddressDetails, fetchData", dataMustBeRetrieved.value, masterDataLoaded.value);
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getAddressDetails(props.id);

    if (response && !response.error) {
      selectedTabIndex.value = setDetails(response.data);
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
    dataMustBeRetrieved.value = false;
  }
}

async function loadSubLocations() {
  const response = await getAddressesOverviewChilds(props.locationReference, 0, 0, undefined, false, undefined);
  console.log("%cAddressDetails, sublocations", "background: GoldenRod; color: black", response);
  if (response && !response.error) {
    subLocations.value = response.data.data;
  }
}

function onSelectSubLocation(item) {
  console.log("AddressDetails, onSelectSubLocation", item);
  // selectedData.value.street = item.compAddr;
  // selectedData.value.pc = item.pc;
  // selectedData.value.city = item.city;
  // selectedData.value.lat = item.lat ? item.lat : 0;
  // selectedData.value.lon = item.lon ? item.lon : 0;
  selectedData.value = { 
      ...selectedData.value,
      lon: item.lon ? item.lon : 0, 
      lat: item.lat ? item.lat : 0,
      city: item.city,
      pc: item.pc,
      street: item.streetName,
      hn: item.houseNumber,
      hl: item.houseLetter,
      hna: item.houseNumberSuffix,
      country : item.cou
    };
}

const obs = ref(null);

async function saveDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    if (isEditTLMode.value) {
      data = { valFrom: selectedData.value.valFrom, valTill: selectedData.value.valTill, modDescr: selectedData.value.modDescr };
    } else {
      data = Object.assign({}, selectedData.value);
      //datums mogen niet geupdate worden
      data.valFrom = null;
      data.valTill = null;
    }
    const result = await patchAddressDetails(selectedData.value.detailId, data);
    if (result.error) {
      proxy.$toaster.error(result.error);
    } else {
      updateSelectedDetail();
      if (isEditTLMode.value) {
        // refresh de data i.v.m. gewijzigde tijdlijn.
        fetchData();
      }
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van dit adres zijn successvol gewijzigd.");
    }
  }
  isSaving.value = false;
}

function addNewDetail() {
  if (selectedData.value) {
    addNewDetailMode();
  } else {
    proxy.$toaster.warning("Selecteer eerst een versie s.v.p..");
  }
}

async function saveNewDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    data = Object.assign({}, selectedData.value);
    data.locRef = props.locationReference;
    const response = await postAddressDetails(data);

    if (!response.error) {
      proxy.$toaster.success("Gegevens van dit adres zijn successvol toegevoegd.");
      if (isAddingMasterMode.value) {
        navigateToLocation(props.locationReference);
      } else {
        setReadonlyMode();
        fetchData();
      }
    } else {
      proxy.$toaster.error(response.error);
    }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
  if (isAddingMasterMode.value) {
    proxy.$toaster.warning(`Adres toevoegen geannuleerd.`);
    navigateToLocation(props.locationReference);
  } else {
    proxy.$toaster.warning(`Adresversie toevoegen geannuleerd.`);
    setReadonlyMode();
    resetSelectedDetail();
  }
}

async function onCancel() {
  proxy.$toaster.warning(`bewerken geannuleerd.`);
  selectNewIndex(selectedTabIndex.value);
  requestAnimationFrame(() => {
    obs.value.reset();
  });
  setReadonlyMode();
}

async function loadAddressableObjectTypes() {
  const response = await getAddressableObjectTypes();
  if (response && !response.error) {
    addressableObjectTypes.value = _.sortBy([...response.data.keyDescList], "description");
    addressableObjectTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres types is mislukt! " + response.error);
  }
}

async function loadAddressableObjectSubTypesHor() {
  const response = await getAddressableObjectSubTypesHor();
  if (response && !response.error) {
    addressableObjectSubTypesHor.value = _.sortBy([...response.data.keyDescList], "description");
    addressableObjectSubTypesHorLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres sub types horizontal is mislukt! " + response.error);
  }
}

async function loadAddressableObjectSubTypesVer() {
  const response = await getAddressableObjectSubTypesVer();
  if (response && !response.error) {
    addressableObjectSubTypesVer.value = _.sortBy([...response.data.keyDescList], "description");
    addressableObjectSubTypesVerLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres sub types vertical is mislukt! " + response.error);
  }
}

async function loadAddressableObjectRoofTypes() {
  const response = await getAddressableObjectRoofTypes();
  if (response && !response.error) {
    addressableObjectRoofTypes.value = _.sortBy([...response.data.keyDescList], "description");
    addressableObjectRoofTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres roof types is mislukt! " + response.error);
  }
}

async function loadAddressTypes() {
  const response = await getAddressTypes();
  if (response && !response.error) {
    addressTypes.value = _.sortBy([...response.data.keyDescList], "description");
    addressTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres types is mislukt! " + response.error);
  }
}

async function loadAddressSourceTypes() {
  const response = await getAddressSourceTypes();
  if (response && !response.error) {
    addressSourceTypes.value = _.sortBy([...response.data.keyDescList], "description");
    addressSourceTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres types is mislukt! " + response.error);
  }
}
</script>

<template>
  <v-container fluid pa-0>
    <header class="app-welcome-message">
      <h2 v-if="!isAddingMasterMode && !isAddingDetailMode">Details van adres</h2>
      <h2 v-else-if="isAddingMasterMode">Toevoegen van adres</h2>
      <h2 v-else-if="isAddingDetailMode">Toevoegen van adresversie</h2>
    </header>

    <v-row>
      <v-col>
        <v-container fluid>
          <v-row align="center">
            <v-col v-if="hasDetails" cols="auto"> Tijdlijn: </v-col>
            <v-col>
              <v-btn-toggle v-model="selectedTabIndex" group mandatory color="blue accent-3">
                <v-btn class="v-application elevation-2" v-for="(item, index) in detailTabs()" :key="index" :value="index" :disabled="!isReadOnly"> {{ item }} </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Geldigheid</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valFrom"
                        persistent-placeholder
                        label="Geldig vanaf"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valTill"
                        persistent-placeholder
                        label="Geldig tot"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" :class="{ noBorders: !isEditDataMode && !isEditTLMode }">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        v-model="selectedData.modDescr"
                        :error-messages="errors"
                        :class="{ noBorders: !allowEdit && !allowEditTL }"
                        :outlined="allowEdit || allowEditTL"
                        :readonly="!allowEdit && !allowEditTL"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Door" v-model="selectedData.modBy" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Datum" v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
            <masonry :cols="{ default: 3, 768: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed">
                <header>Locatie definitie</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Referentie" rules="required|max:50" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        label="Referentie"
                        :counter="50"
                        v-model="selectedData.ref"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field hide-details="auto" label="Omschrijving" v-model="selectedData.descr" :outlined="allowEdit" :class="{ noBorders: !allowEdit }" :readonly="!allowEdit"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Type"
                        :items="addressableObjectTypes"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.typ"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="SubTypeHor" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Sub type"
                        :items="addressableObjectSubTypesHor"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.subTypHor"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider name="SubTypeVer" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Etage"
                        :items="addressableObjectSubTypesVer"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.subTypVer"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="RoofType" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Dak type"
                        :items="addressableObjectRoofTypes"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.roofTyp"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Adres</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="AddressType" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Adres type"
                        :items="addressTypes"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.adrTyp"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field hide-details="auto" label="Straatnaam" v-model="selectedData.street" :outlined="allowEdit" :class="{ noBorders: !allowEdit }" :readonly="!allowEdit"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Huisnummer" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Huisnummer"
                        v-model="selectedData.hn"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider name="Huisletter" rules="max:1" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Huisletter"
                        v-model="selectedData.hl"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details="auto" label="Toevoeging" v-model="selectedData.hna" :outlined="allowEdit" :class="{ noBorders: !allowEdit }" :readonly="!allowEdit"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <ValidationProvider name="Postcode" rules="postalcode" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Postcode"
                        v-model="selectedData.pc"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details="auto" label="Woonplaats" v-model="selectedData.city" :outlined="allowEdit" :class="{ noBorders: !allowEdit }" :readonly="!allowEdit"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field hide-details="auto" label="Land" v-model="selectedData.country" :outlined="allowEdit" :class="{ noBorders: !allowEdit }" :readonly="!allowEdit"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Positie</header>
                <v-row>
                  <v-col cols="12">
                    <location-map :location="coordinates"></location-map>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Breedtegraad" rules="double|between:-180,180" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Breedtegraad (lat)"
                        :value="selectedData.lat"
                        @input="selectedData.lat = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider name="Lengtegraad" rules="double|between:-180,180" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Lengtegraad (lon)"
                        :value="selectedData.lon"
                        @input="selectedData.lon = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Herkomst</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bron" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Bron"
                        :items="addressSourceTypes"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.srcTyp"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <!-- v-if="selectedData.srcTyp !== 'BAG'" -->
                  <v-col v-show="!sourceIsBag">
                    <v-text-field
                      hide-details="auto"
                      label="Bron referentie"
                      v-model="selectedData.srcRef"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col v-show="sourceIsBag">
                    <ValidationProvider name="BAG-id" :rules="`${sourceIsBag ? 'numeric|min:15|max:16' : ''}`" v-slot="{ errors }" mode="lazy">
                      <v-text-field
                        hide-details="auto"
                        label="BAG-id"
                        v-model="selectedData.srcRef"
                        :counter="18"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Prijzen</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs elektriciteit" rules="double|decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Elektriciteit"
                        :value="selectedData.priceElectricity"
                        @input="selectedData.priceElectricity = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/kWh</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs gas" rules="double|decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Gas"
                        :value="selectedData.priceGas"
                        @input="selectedData.priceGas = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/Nm³</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs biomassa" rules="double|decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Biomassa"
                        :value="selectedData.priceBiomass"
                        @input="selectedData.priceBiomass = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/GJ</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs warmte" rules="double|decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Warmte"
                        :value="selectedData.priceHeat"
                        @input="selectedData.priceHeat = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/GJ</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs koude" rules="double|decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Koude"
                        :value="selectedData.priceCold"
                        @input="selectedData.priceCold = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/GJ</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Gebruik</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Werkruimte" rules="double|decimal:2|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        name="Werkruimte"
                        label="Werkruimte"
                        :value="selectedData.workspace"
                        @input="selectedData.workspace = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">m²</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="FTE" rules="double|decimal:1|between:0,99999999.9" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="FTE"
                        :value="selectedData.fte"
                        @input="selectedData.fte = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bezettingsgraad" rules="double|between:0,100.00|decimal:2" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Bezettingsgraad"
                        :value="selectedData.occupancyRate"
                        @input="selectedData.occupancyRate = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">%</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Verhuurgraad" rules="double|between:0,100.00|decimal:2" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Verhuurgraad"
                        :value="selectedData.rentalRate"
                        @input="selectedData.rentalRate = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">%</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Gebruiksuren" rules="double|between:0,9999999.99|decimal:2" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Gebruiksuren"
                        :value="selectedData.hourOfUse"
                        @input="selectedData.hourOfUse = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">uren/week</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field hide-details="auto" label="Gebruikstijd" v-model="selectedData.usageTime" :outlined="allowEdit" :class="{ noBorders: !allowEdit }" :readonly="!allowEdit">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Extra</header>
                <v-row>
                  <v-col>
                    <v-text-field hide-details="auto" label="Planningshorizon" v-model="selectedData.planningHorizon" :outlined="allowEdit" :class="{ noBorders: !allowEdit }" :readonly="!allowEdit">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Datascore" rules="double|between:0,9999999.99|decimal:2" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Datascore"
                        :value="selectedData.dataScore"
                        @input="selectedData.dataScore = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
            <masonry v-if="addModus" :cols="{ default: 1 }" :gutter="12">
              <v-container class="boxed">
                <header>Gegevens overnemen van sub locatie</header>
                <v-row>
                  <v-col>
                    <v-data-table class="bag" dense disable-sort disable-pagination v-model="subLocationSelected" :headers="subHeaders" hide-default-footer :items="subLocations" item-key="detailId">
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn @click="onSelectSubLocation(item)" icon small plain>
                          <v-icon small> mdi-forward </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>

            <details-buttons
              :addMode="isAddingMode"
              :editMode="!isReadOnly"
              :allowSave="!isSaving"
              @edit="setEditMode"
              @edit-tl="setEditTLMode"
              @on-cancel="onCancel"
              @add-new-detail="addNewDetail"
              @on-cancel-add="onCancelAdd()"
              @save-detail="saveDetail"
              @save-new-detail="saveNewDetail"
            />
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
