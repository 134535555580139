import { apiClient } from "./httpClient";

const END_POINT = "addressableobject";

const getAddressDetails = async addressId => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${addressId}/details`).catch(error => {
    errorResponse = error.toString();
  });

  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchAddressDetails = async (addressId, data) => {
  let errorResponse = undefined;
    const response = await apiClient.patch(`${END_POINT}/${addressId}`, { ...data }).catch(error => {
      switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = "Er is een fout opgetreden. " + error.toString();
    }
  });
  if (response) {
    switch (response.status) {
        // de catch vangt geen 200-range meldingen af
        case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

const postAddressDetails = async (data) => {
  let errorResponse = undefined;
  let response = await apiClient.post(`${END_POINT}`, { ...data }).catch(error => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

export { getAddressDetails, patchAddressDetails, postAddressDetails };
